import './scss/app.scss';
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

import 'slick-carousel';

$(document).ready(function () {


    $('.current.lng').click(function () {
        $(this).toggleClass('open').next('.list-in').slideToggle(200);
    });
    $('.your-slider').slick({
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: '10%',
        rows: 0,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        slide: '.slick-slider-in',
        responsive: [
            {
                breakpoint: 1204,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    });
    // AOS.init
    AOS.init();


    $('#openPopup').click(function () {
        $('#registrationForm').show();
    });
    $(document).on('click', '.form .close', function () {
        $('.container-popup').hide();
    });


    $('.close_btn').click(function () {
        $(".close_btn").parent().parent().hide().parent().hide();
    })

    $('.gold_crown').click(function (e) {
        e.preventDefault(e);
        $('.container-popup').show().find('.gold_crown_container').show();
    });
    $('.gold_star').click(function (e) {
        e.preventDefault(e);
        $('.container-popup').show().find('.gold_star_container').show();

    });

    $('.log-in-popup').click(function (e) {
        e.preventDefault(e);
        $('.container-popup').show();
        $('#registrationForm').hide();
        $('#log-in-affiliate').show();
        $('#forgot-password').hide();
    });
    $('.sing-in-popup').click(function (e) {
        e.preventDefault(e);
        $('.container-popup').show();
        $('#registrationForm').show();
        $('#log-in-affiliate').hide();
        $('#forgot-password').hide();
    });
    $('.forget-password').click(function (e) {
        e.preventDefault(e);
        $('.container-popup').show();
        $('#registrationForm').hide();
        $('#log-in-affiliate').hide();
        $('#forgot-password').show();
    });
    $('.password-show').click(function () {
        let passwordField = $(this).parent().find('input');
        let passwordFieldType = passwordField.attr('type');
        if (passwordFieldType == 'password') {
            passwordField.attr('type', 'text');
        } else {
            passwordField.attr('type', 'password');
        }
    });
    // $(document).click(function (event) {
    //     var $target = $(event.target);
    //     if (!$target.closest('.form-container').length) {
    //         $('.container-popup').hide();
    //     }
    // });

    var password = document.getElementById("password")
        , confirm_password = document.getElementById("password_confirmation");

    function validatePassword() {
        if (password.value != confirm_password.value) {
            confirm_password.setCustomValidity("Passwords Don't Match");
        } else {
            confirm_password.setCustomValidity('');
        }
    }

    password.onchange = validatePassword;
    confirm_password.onkeyup = validatePassword;


    document.getElementById('registrationForm').addEventListener('submit', function (event) {
        event.preventDefault();


        const formData = {
            partner_user: {
                email: document.getElementById('email').value,
                password: document.getElementById('password').value,
                password_confirmation: document.getElementById('password_confirmation').value,
                company_name: document.getElementById('company_name').value,
                traffic_sources: document.getElementById('traffic_sources').value,
                skype: document.getElementById('skype').value,
                telegram: document.getElementById('telegram').value,
                terms_accepted: document.getElementById('terms_accepted').checked,
                receive_newsletter: document.getElementById('receive_newsletter').checked,

            }
        };

        // https://login.starcrown.partners
        // https://login.starcrown.partners/api/client/partner
        fetch('https://login.starcrown.partners/api/client/partner', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Response: ' + JSON.stringify(data));
                // Handle email error
                if (data.errors) {
                    if (data.errors.email) {
                        $('#email').parent().find('.error-text').text(data.errors.email[0]).show();
                    }
                    // Handle password error
                    if (data.errors.password) {
                        $('#password').parent().find('.error-text').text(data.errors.password[0]).show();
                    }  // Handle password error
                    if (data.errors.skype) {
                        $('#skype').parent().find('.error-text').text(data.errors.skype[0]).show();
                    }
                    // Trigger the form validation to show error messages


                } else {
                    $('#registrationForm').html(' <span class="close"></span><h3>We will let you know when we approve your account.</h3> ');
                    setTimeout(function () {
                        $('.container-popup').hide();
                        $('#registrationForm').hide();
                        $('#log-in-affiliate').hide();
                        $('#forgot-password').hide();
                    }, 3000)

                    $('.form-container .error-text').each(function () {
                        $(this).hide()
                    })
                }

            })
            .catch(error => {
                console.error('Error:', error);
            });
    });
    document.getElementById('log-in-affiliate').addEventListener('submit', function (event) {
        event.preventDefault();
        const formData = {
            partner_user: {
                email: document.getElementById('email-1').value,
                password: document.getElementById('password-1').value,
                otp_attempt: document.getElementById('two-factor').value,


            }
        };
        // https://login.starcrown.partners

        // https://login.starcrown.partners/api/client/partner
        fetch('https://login.starcrown.partners/api/client/partner/sign_in', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Response: ' + JSON.stringify(data));
                if (data.errors) {


                    if (data.errors.email) {
                        $('#email-1').parent().find('.error-text').text(data.errors.email[0]).show();
                    }

                    if (data.errors.password) {
                        $('#password-1').parent().find('.error-text').text(data.errors.password[0]).show();
                    }
                } else {
                    if (data.error) {
                        $('#log-in-affiliate-error').text(data.error).show();
                    } else {
                        // Store user data in local storage or session storage
                        sessionStorage.setItem('userData', JSON.stringify(data));
                        sessionStorage.setItem('authToken', data.auth_token);

                        // Function to set a cookie
                        function setCookie(name, value, days) {
                            const date = new Date();
                            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                            const expires = "expires=" + date.toUTCString();
                            document.cookie = name + "=" + value + ";" + expires + ";path=/";
                        }

                        setCookie('_session_id', data.otp_secret, 1);
                        // Redirect to the dashboard page
                        window.location.href = 'https://login.starcrown.partners/partner/dashboard';

                        $('#log-in-affiliate-error').hide();
                        setTimeout(function () {
                            $('.container-popup').hide();
                            $('#registrationForm').hide();
                            $('#log-in-affiliate').hide();
                            $('#forgot-password').hide();
                        }, 3000)
                        $('.form-container .error-text').each(function () {
                            $(this).hide()
                        })
                    }

                }

            })
            .catch(error => {
                console.error('Error:', error);
            });
    });

    document.getElementById('forgot-password').addEventListener('submit', function (event) {
        event.preventDefault();

        // Hide any previous error messages
        const errorText = document.querySelector('#email-2').parentElement.querySelector('.error-text');
        errorText.style.display = 'none';

        const formData = {
            partner_user: {
                email: document.getElementById('email-2').value,
            }
        };

        fetch('https://login.starcrown.partners/api/client/partner/password', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text().then(text => text ? JSON.parse(text) : {});
            })
            .then(data => {
                console.log('Response: ' + JSON.stringify(data));
                if (data.errors) {
                    if (data.errors.email) {
                        errorText.textContent = data.errors.email[0];
                        errorText.style.display = 'block';
                    }
                } else {
                    setTimeout(function () {
                        document.querySelectorAll('.container-popup, #registrationForm, #log-in-affiliate, #forgot-password').forEach(el => el.style.display = 'none');
                    }, 3000);
                    document.querySelectorAll('.form-container .error-text').forEach(el => el.style.display = 'none');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle fetch errors or other exceptions
                errorText.textContent = 'An error occurred. Please try again later.';
                errorText.style.display = 'block';

            });
    });
});